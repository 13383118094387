.login-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(178deg, #7a8997 0%, #9198e5 100%);

  .login-wrapper {
    width: 420px;
    padding: 75px 40px;
    display: flex;
    flex-direction: column;
    gap: 50px;

    .logo-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;

      .header-text {
        font-size: 40px;
      }

      .p-avatar.p-avatar-xl {
        width: 7rem;
        height: 7rem;
      }
    }

    .input-wrapper {
      .header-input-text {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 136%;
        letter-spacing: 0.1px;
        height: 27px;
      }
      .p-inputtext {
        width: 100%;
        border-radius: 25px;
        background: #ffffff45;
      }

      .login-input {
        &.p-inputtext {
          padding-left: 3rem;
        }
        &::placeholder {
          color: #f6f6f6bc;
        }
      }

      .login-input-password {
        .p-inputtext {
          padding-left: 3rem;
        }

        ::placeholder {
          color: #f6f6f6bc;
        }

        .p-icon {
          color: #00000075;
        }
      }

      .p-input-icon-left > i:first-of-type,
      .p-input-icon-left > svg:first-of-type,
      .p-input-icon-left > .p-input-prefix {
        left: 1rem;
      }

      .p-error {
        color: rgb(218, 51, 51);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 144%;
        letter-spacing: 0.17px;
        height: 19px;
      }

      .password-header-warpper {
        height: 27px;
        display: flex;
        justify-content: space-between;

        .forgot-password {
          text-align: right;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 166%;
          letter-spacing: 0.4px;

          cursor: pointer;
        }
      }

      .login-btn {
        display: flex;
        height: 44px;
        padding: 6px 12px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        flex-shrink: 0;

        background: #689f38;
        border: 1px solid #ffffff00;

        border-radius: 25px;

        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 136%;
        letter-spacing: 0.1px;
      }
    }
  }

  .moutain {
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
