.template-list-box-table-container {
  .template-data-header {
    font-size: 1.5em;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    font-weight: bold;
  }

  .card-template {
    position: relative;
    background: #ffffff;
    padding: 2rem;
    border-radius: 10px;
    // margin-bottom: 2rem;

    .card-edit-text {
      position: absolute;
      top: 0px;
      right: 10px;
      color: #bcbcbc77;
      font-size: 50px;
      text-align: center;
      user-select: none;
    }
  }

  .manual-data-table-container,
  .manual-box-header {
    .p-disabled {
      background: #c4c4c4;
    }
  }

  .invalid {
    .p-checkbox-box {
      border-color: #e24c4c;
    }
  }

  .query-data-header {
    font-size: 1.5em;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    font-weight: bold;
  }
  .template-name {
    margin-bottom: 10px;
    font-size: 2rem;
    font-weight: bold;
  }

  .template-description {
    .p-inplace-display {
      padding: 0 15px;
      color: rgb(108, 107, 107);
    }
  }

  .create-template-name-input {
    font-size: 2rem;
    font-weight: bold;
  }

  // .template-field-set-table {
  //   height: 50%;
  // }
}

.query-report-side-bar-container {
  .p-disabled {
    background: #c4c4c4;
  }
}
