.calendar-wrapper {
  align-items: start;

  .from-calendar,
  .to-calendar {
    .p-inputtext {
      width: 1px;
      opacity: 0;
      padding: 0;
    }
  }
  .date-picker-wrapper {
    padding: 5px 10px;
  }
  .error-from-date-background,
  .error-to-date-background {
    background: #ff000042;
    padding: 5px 10px;
    border-radius: 7px;
  }

  .calendar-end-of-dropdown-btn {
    background: #ffffff;
    padding: 7px 9px;
    border: 1px solid #d8dee9;
    cursor: pointer;
    border-radius: 4px;

    .pi {
      font-size: 1.1rem;
    }

    &:hover {
      background-color: #e0e4ec;
      border-color: #81a1c1;
    }

    &.disabled {
      background-color: #c4c4c4ba;
      cursor: auto;
      border-color: #d8dee9;
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  // blue
  .calendar-wrapper {
    gap: 0.25rem;
    flex-direction: column;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  // yellow
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  // orange
}

/* more Extra large devices (large laptops and desktops, 1600px and up) */
@media only screen and (min-width: 1600px) {
  // purple
  .calendar-wrapper {
    gap: 2rem;
    flex-direction: row;
    align-items: center;
  }
}
