.manual-report-container {
  .card {
    background: #ffffff;
    padding: 2rem;
    border-radius: 10px;
    margin-bottom: 1rem;
  }
  .manual-data-table-container,
  .manual-box-header {
    .p-disabled {
      background: #c4c4c4;
    }
  }

  .invalid {
    .p-checkbox-box {
      border-color: #e24c4c;
    }
  }
}

.create-template-dialog {
  .query-data-dialog {
    padding: 10px 15px;
    margin: 2px 2px;
    border: 1px solid black;
    border-radius: 20px;
    width: 190px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.query-report-side-bar-container {
  .header-export-btn {
    background: #ffffff;
    color: #4c566a;
    border: 1px solid #ffffff;
    border-width: 1px 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
  }
  .query-data-dialog {
    padding: 10px 15px;
    margin: 2px 2px;
    border: 1px solid black;
    border-radius: 20px;
    width: 190px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.list-box-table-container {
  .query-data-header {
    font-size: 1.5em;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    font-weight: bold;
  }
}

.query-report-side-bar-container {
  .p-disabled {
    background: #c4c4c4;
  }
}

.dialog-box-create-template {
  border: 1px solid #d8dee9;
  border-radius: 4px;

  .header {
    margin-top: -17px;
    text-align: center;
    margin-block-end: 0.83em;

    .text {
      font-size: 1.5em;
      background: white;
      padding: 0 14px;
      font-weight: bold;
    }
  }
}
