.sidebar-container {
  * {
    text-decoration: none;
  }

  display: flex;
  margin: 0;
  padding: 0;

  .content {
    width: 100%;
    // background: #f5f5f5;
    background: #e8e7e7;

    .bars {
      padding: 24px 15px;
      display: flex;
      justify-content: space-between;

      .pi-align-justify {
        cursor: pointer;
      }
    }

    main {
      // padding: 20px;
      padding: 0 20px 20px 20px;
      // padding: 20px 20px 90px 20px;
      max-height: 100vh;
      overflow: scroll;
    }
  }

  .sidebar {
    background: #3c4962;
    color: #fff;
    height: 100vh;
    width: 300px;
    transition: all 0.5s;

    .top_section {
      display: flex;
      align-items: center;
      padding: 20px 15px;
      justify-content: space-around;
      align-items: center;

      .logo {
        // font-size: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 40px;
          height: 40px;
        }
      }

      .ntt-text-logo {
        font-size: 22px;
        font-weight: 500;
      }
    }

    .list-container {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 0 5px;
      height: 90vh;

      .link {
        display: flex;
        color: #fff;
        padding: 10px 15px;
        gap: 15px;
        transition: all 0.5s;

        &.logout-link {
          margin-top: auto;
        }

        &:hover {
          background: #59667d;

          transition: all 0.5s;

          border-radius: 10px;
        }

        &.active {
          background: #59667d;

          border-radius: 10px;
        }

        .link_text {
          font-size: 20px;
        }
      }
    }
  }

  .sql-icon-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 16px;
    font-weight: bold;

    > {
      position: absolute;
      top: 0;
    }
  }

  .check {
    bottom: 0;
    right: -5px;
    background: #ffffff;
    border-radius: 15px;
  }

  .blink {
    animation: blink-animation 1.5s steps(5, start) infinite;
    -webkit-animation: blink-animation 1.5s steps(5, start) infinite;
  }

  @keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }

  @-webkit-keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }
}
