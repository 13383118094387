.list-report-container {
  .card {
    background: #ffffff;
    padding: 2rem;
    border-radius: 10px;
    margin-bottom: 1rem;
  }
  .list-data-table {
    .p-disabled {
      background: #c4c4c4;
    }
  }
}
