.fieldset-container {
  border-radius: 10px;

  .p-fieldset-legend {
    border-radius: 10px;
  }

  .p-fieldset-content {
    padding: 1.2rem;
  }

  .p-disabled {
    background: #c4c4c4;
  }
}
