.log-container {
  .card {
    background: #ffffff;
    padding: 2rem;
    border-radius: 10px;
    margin-bottom: 1rem;
  }
  .list-data-table {
    .p-disabled {
      background: #c4c4c4;
    }
  }

  .currently-active {
    border: 1px solid rgb(135, 135, 135);
    padding: 10px 25px;
    border-radius: 10px;
    color: green;
  }
}
